$(function () {


    /****************************************
     * 下層(sub page)
     ****************************************/

    (function () {
        // #js-slide
        var recruitSwiper = new Swiper('#js-slide--recruit', {
            pagination: '.swiper-pagination',
            speed: 800,
            slidesPerView: 3,
            centeredSlides: true,
            spaceBetween: 10,
            grabCursor: true,
            loop: true,
            paginationClickable: true,
            pagination: '.swiper-pagination',
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            breakpoints: {
                // when window width is <= 320px
                768: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                    nextButton: null,
                    prevButton: null,
                }
            },
        });
    }).call(this);
    $(".questions").on("click", function () {
        if ($(this).parent().hasClass("in")) {
            $(this).parent().removeClass("in");
            $(this).parent().addClass("out");
        } else {
            $(this).parent().removeClass("out");
            $(this).parent().addClass("in");
        }
    });
});
